<template>
  <div>
    <!-- 1.头部横幅   v-lazy:background-image="require('./photo/banner1.jpg')"-->
    <div class="banner">
      <!-- 横幅内容区域 -->
      <img v-lazy="require('./photo/banner1.jpg')" alt="">
     <div>
        <h2 class="title">大运河能力聚合</h2>
      <p class="introduce">
        大运河智慧中心，聚合城市的力量
      </p>
      <div round @click="showdlg">立即加入</div>
     </div>
    </div>
    <!-- 2 高标准建设文旅供应链-->
    <div class="twobig">
      <h2>高标准建设文旅供应链</h2>
      <div class="flexbox">
        <!-- 1 -->
        <div class="big">
          <div class="icon">
            <img src="./photo/indicate.png" alt="" />
          </div>
          <div class="text">健全的供应链管理指标考 核体系</div>
        </div>
        <!-- 2 -->
        <div class="big">
          <div class="icon2">
            <img src="./photo/zan.png" alt="" />
          </div>
          <div class="text">支持供应链创新、提倡持 续改进的企业文化</div>
        </div>
        <!-- 3 -->
        <div class="big">
          <div class="icon3">
            <img src="./photo/quan.png" alt="" />
          </div>
          <div class="text">完善的供应链风险控制流 程</div>
        </div>
        <!-- 4 -->
        <div class="big">
          <div class="icon4">
            <img src="./photo/ren.png" alt="" />
          </div>
          <div class="text">应和需求相互协调匹配， 实现精益供应链</div>
        </div>
      </div>
    </div>
    <!-- 3 背图 -->
    <div class="threebigs">
      <div class="fontext">
        构建打通文旅产业供需 有效连接的文旅新场景
      </div>
    </div>
    <!-- 4 全方位构建运河服务体系-->
    <div class="fourbig">
      <div class="onetext">全方位构建运河服务体系</div>
      <div class="twotext">
        通过数字化的方式，实现用户全程一站式无障碍的服务体验，提升品质服务
      </div>
      <div class="three">
        <div>
          <img src="./photo/oneimg.png" alt="" />
          <div class="wenzi">构建全域文旅融合发展体系</div>
        </div>
        <div>
          <img src="./photo/twoimg.png" alt="" />
          <div class="wenzi">构建文旅新产品供给体系</div>
        </div>
        <div>
          <img src="./photo/threeimg.png" alt="" />
          <div class="wenzi">构建优质文旅服务供给体系</div>
        </div>
      </div>
    </div>
    <!-- 5 foot图片 -->
    <div class="sixbg">
      
    </div>
    <!-- 对话框 -->
    <div class="dilog" v-show="showdia">
      <div class="close" @click="closedel"></div>
      <div class="imgtop"></div>
      <div class="imgleft"></div>
      <div class="textone">加入我们</div>
      <div class="texttwo">CONTACT &nbsp US</div>
      <div class="callwe">
        <img src="./photo/userwe.png" class="users" title="联系人" />
        <div class="texts">周骏飞</div>
        <img src="./photo/usertel.png"  title="联系电话" class="tel" />
        <div class="texts">18013886200</div>
      </div>
    </div>
    <!-- 遮遮城 -->
    <div class="mocks" v-show="showdia" @click="closedlgs"></div>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "powersum",
  components: {
    Header,
    Footer,
  },
  methods: {
    showdlg() {
      this.showdia = true;
    },
    closedel() {
      this.showdia = false;
    },
    closedlgs() {
      this.closedel();
    },
  },
  mounted() {},
  data() {
    return {
      showdia: false,
    };
  },
};
</script>
<style lang="scss" scoped>
// 背景
* {
  box-sizing: border-box;
}
.banner {
  height: 600px;
  width: 100%;
  color: #fff;
  
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: top 0;
    img[lazy="loading"] {
    margin-top: 150px;
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
	height: 600px;
  width: 100%;
	}
  position: relative;
  div{
    .title {
    width: 412px;
    height: 67px;

    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    position: absolute;
    left: 330px;
    top: 230px;
    font-size: 48px;
    
    font-weight: 500;
    color: #fff;
  }
  .introduce {
    width: 697px;
    height: 26px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 360px;
    top: 317px;
  }
  div {
    background: #007aff;
    color: white;
    outline: none;
    border: none;
    width: 168px;
    height: 48px;
    line-height: 48px;
    position: absolute;
    left: 360px;
    top: 397px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 24px;
  }
  div:hover {
    cursor: pointer;
  }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
  width: 300px;
  height: 80px;
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
// 2
.twobig {
  height: 590px;
  padding-top: 80px;
  h2 {
    height: 56px;
    font-size: 40px;
    
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  .flexbox {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .big {
      width: 278px;
      height: 292px;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      .icon {
        width: 56px;
        height: 56px;
        margin: 62px auto 28px;

        img {
          width: 100%;
        }
      }
      .icon2 {
        width: 38px;
        height: 40px;
        margin: 62px auto 42px;
        img {
          width: 100%;
        }
      }
      .icon3 {
        width: 76px;
        height: 76px;
        margin: 44px auto 24px;
        img {
          width: 100%;
        }
      }
      .icon4 {
        width: 47px;
        height: 53px;
        margin: 62px auto 28px;
        img {
          width: 100%;
        }
      }
      .text {
        width: 230px;
        margin: 0 auto;

        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
//3
.threebigs {
  padding-top: 305px;
  height: 722px;
  background: url("./photo/threebg.png") no-repeat center;
  background-size: 100% 100%;
  .fontext {
    width: 413px;
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin: 0 auto;
  }
}
//4
.fourbig {
  .onetext {
    font-size: 40px;
    
    font-weight: 500;
    color: #333333;
    margin: 79px 0 24px 0;
  }
  .twotext {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #565656;
    margin-bottom: 60px;
  }
  .three {
    height: 333px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    img {
      width: 380px;
      height: 260px;
      vertical-align: middle;
    }
    .wenzi {
      height: 77px;
      line-height: 77px;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
      border-radius: 6px;

      font-size: 18px;
      
      font-weight: 500;
      color: #333333;
    }
  }
}
.sixbg {
 height: 100px;
}
// 5 对话
.dilog {
  z-index: 2;
  width: 544px;
  height: 320px;
  position: absolute;
  left: 700px;
  top: 374px;
  background: url("./photo/dlog.png") no-repeat center;
  background-size: 100% 100%;
  .imgtop {
    width: 148px;
    height: 120px;
    background: url("./photo/hetop.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    right: -40px;
    top: -21px;
  }
  .imgleft {
    width: 156px;
    height: 118px;
    background: url("./photo/heleft.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    left: -44px;
    bottom: -2px;
  }
  .textone {
    font-size: 32px;
    
    font-weight: 500;
    color: #856959;
    line-height: 24px;
    margin-top: 101px;
    margin-bottom: 14px;
  }
  .texttwo {
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #856959;
    letter-spacing: 2px;
  }
  .callwe {
    margin-top: 47px;
    padding-left: 85px;
    display: flex;
    .users {
      width: 24px;
      height: 24px;
      z-index: 999;
      z-index: 40;
      
    }
    .tel {
      width: 24px;
      height: 24px;
      margin-left: 130px;
     
    }
    .texts {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #856959;
    }
  }
  .close {
    width: 25px;
    height: 24px;
    background: url("./photo/closeone.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: -35px;
    right: -24px;
    z-index: 5;
  }
  .close:hover {
    cursor: pointer;
  }
}
.mocks {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>
